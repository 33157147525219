import React from 'react'

const WhyChooseUs = () => {
    return (
        <>
            <section className="tf__about_us_page mt_195 xs_mt_100">
                <div className="tf__about_2_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-duration="1.5s">
                                <div className="tf__about_2_img">
                                    <div className="tf__about_small">
                                        <img src="./assets/images/aqta/5.jpeg" alt="about us" className="img-fluid w-100" />
                                    </div>
                                    <div className="tf__about_large">
                                        <img src="./assets/images/Why Choose Us.png" alt="about us" className="img-fluid w-100" />
                                    </div>
                                    {/* <p style={{ textTransform: "capitalize" }}><span></span> Al-Qalam Triangle Academy</p> */}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 wow fadeInRight" data-wow-duration="1.5s">
                                <div className="tf__about_2_text" style={{marginTop:"36px"}}>
                                    <div className="tf__heading_area tf__heading_area_left mb_25">
                                        {/* <h5>Al-Qalam Triangle Academy</h5> */}
                                        <h2>Why Choose Us</h2>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <img src="./assets/images/about_2_icon_1.jpg" alt="about" className="img-fluid w-100" />
                                            </div>
                                            <div className="text">
                                                <h4>Islamic Montessori Education</h4>
                                                <p>A unique blend of Montessori principles and
                                                    Islamic teachings that nurtures the whole child.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img src="./assets/images/about_2_icon_2.jpg" alt="about" className="img-fluid w-100" />
                                            </div>
                                            <div className="text">
                                                <h4>Holistic Development</h4>
                                                <p>A focus on spiritual, academic, and social growth to prepare children for life’s challenges and opportunities.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img src="./assets/images/about_2_icon_3.jpg" alt="about" className="img-fluid w-100" />
                                            </div>
                                            <div className="text">
                                                <h4>Inclusive Community</h4>
                                                <p>A welcoming environment that celebrates diversity and fosters unity</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img src="./assets/images/about_2_icon_4.jpg" alt="about" className="img-fluid w-100" />
                                            </div>
                                            <div className="text">
                                                <h4>Strong Partnerships</h4>
                                                <p>Active collaboration with parents and the community to support each child’s growth and development</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img src="./assets/images/about_2_icon_3.jpg" alt="about" className="img-fluid w-100" />
                                            </div>
                                            <div className="text">
                                                <h4>Safe and Nurturing Spaces</h4>
                                                <p> A caring environment where children feel valued, respected, and empowered to explore their potential.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default WhyChooseUs