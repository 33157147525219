import React from 'react'
import Footer from '../../component/layout/Footer'

const ArabicCurriculum = () => {
    return (
        <>
            <section className="tf__event_details mt_195 xs_mt_100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <br />
                            <br />
                            <br />
                            <div className="tf__event_details_img wow fadeInUp" data-wow-duration="1.5s">
                                <img src="./assets/images/aqta/Copy1.jpeg" alt="event details" className="img-fluid w-100" />
                            </div>
                            <div className="tf__event_details_text mt_35 wow fadeInUp" data-wow-duration="1.5s">
                                <span className="categories" style={{ textTransform: "capitalize" }}>Al-Qalam Academy
                                </span>
                                <h2>Conversation (Oral Expression Skills)</h2>
                                <p> <b> Objective: </b> Develop fluency and confidence in spoken Arabic through interactive and practical
                                    activities. </p>

                                <div className="row">
                                    {/* <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_img">
                                            <img src="./assets/images/event_det_center_img.jpg" alt="event" className="img-fluid w-100" />
                                        </div>
                                    </div> */}
                                    <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_text">
                                            <h3>Montessori Approach</h3>
                                            <ul>
                                                <li>Role-Playing: Create real-life scenarios (e.g., market, home, or mosque) where
                                                    students practice conversational Arabic.</li>
                                                <li>Daily Greetings: Incorporate Arabic greetings and phrases into the daily routine (e.g.,
                                                    “Sabah al-khair,” “Kaifa Haluka?”).</li>
                                                <li>Show and Tell: Encourage students to bring an item from home and describe it in
                                                    Arabic.</li>
                                                <li>Group Discussions: Facilitate small group discussions on topics like family, hobbies, or
                                                    Islamic holidays.</li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>

                                <h3>Materials:</h3>
                                <li> Conversation cards with prompts (e.g., “What did you do today?”) </li>
                                <li>Props for role-playing (e.g., toy food, prayer rugs, or costumes).</li>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />



        </>
    )
}

export default ArabicCurriculum