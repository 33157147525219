import React from 'react'

const HomeIslam = () => {
    return (
        <>
            <section className="tf__about_us_page mt_195 xs_mt_100" style={{overflow:"hidden"}}>
                <div className="tf__faq mt_100 pt_95 pb_100" style={{ background: 'url(./assets/images/faq_bg.png)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-duration="1.5s">
                                <div className="tf__faq_img">
                                    <img src="./assets/images/Islam.png" alt="faqs" className="img-fluid w-100" />
                                    {/* <img src="./assets/images/aqta/10.JPEG" alt="faqs" className="img-fluid w-100" /> */}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 wow fadeInRight" data-wow-duration="1.5s">
                                <div className="tf__faq_text">
                                    <div className="tf__heading_area tf__heading_area_left mb_25">
                                        <h5>Welcome to Al-Qalam Triangle Academy</h5>

                                    </div>
                                    <div className="tf__faq_accordion">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item orange">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <b>Islam</b>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>🔹 Islam is the final message revealed from God to mankind. </p>
                                                        <p>
                                                            🔹 Our faith is a perfect and comprehensive way of life.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item green">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Learning and Learners
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>🔹 All learners can learn, but they have varying learning styles. </p>
                                                        <p>🔹 They shall be well learned in Islam and greatly proud of it.</p>
                                                        <p>🔹 Learners feel good about the school they attend.</p>
                                                        <p>🔹 They are capable of self-discipline if trained </p>
                                                        <p>
                                                            🔹 All learners are gifted, and our job is to figure out how they are gifted.
                                                        </p>
                                                        <p>🔹 They have multiple and various kinds of intelligences and they develop at different rates </p>
                                                        <p>🔹 They develop the ability to think creatively, critically and rationally.  </p>
                                                        <p>🔹 They have a safe, healthy and Islamic environment, and they must have social as well as intellectual experiences.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item orange">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Parents
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p> 🔹 They actively support the Islamic and academic mission of the school</p>
                                                        <p> 🔹 They serve as role models. </p>
                                                        <p> 🔹  Parents adhere to the policies set forth by the school.</p>
                                                        <p> 🔹 Parents are treated in a professional manner. </p>
                                                        <p> 🔹  They set high expectations for their children and positively reinforce their child’s efforts.</p>
                                                        <p> 🔹 They are made aware of teacher expectations, and they actively support teachers’ efforts.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item green">
                                                <h2 className="accordion-header" id="headingThree1">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                                                    Staff and Faculty
                                                    </button>
                                                </h2>
                                                <div id="collapseThree1" className="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>🔹 Staff and faculty members are role models, for actions outlast spoken words in the young tender brains.</p>
                                                        <p>🔹  Staff members actively support the Islamic and academic mission of the school.</p>
                                                        <p>🔹 They are genuinely interested in the general welfare of students and display a positive attitude towards them and their learning.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item orange">
                                                <h2 className="accordion-header" id="headingThree1">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                                                    Administration (Board and School Management)
                                                    </button>
                                                </h2>
                                                <div id="collapseThree1" className="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>🔹  Administrators should cooperatively establish a vision and should disseminate that vision to all stakeholder groups.</p>
                                                        <p>🔹  They should be knowledgeable about child growth and development, curriculum construction and alignment, learning modes, supervision, and exemplary management theories and practices. </p>
                                                        <p>🔹 They should know how to manage, monitor and work with people.</p>
                                                        <p>🔹 They should focus on student learning and mastery, and they should encourage close monitoring of student achievement and the supplying of feedback.</p>
                                                        <p>🔹 Administrators should provide instructional support for teachers.</p>
                                                        <p>🔹 They should practice effective supervision, including growth target identification, monitoring, conferencing, feedback and evaluation.</p>
                                                        <p> 🔹 Administrators are responsible for developing and maintaining a safe, orderly and healthy environment.</p>
                                                        <p> 🔹 They should establish high expectations for behavior and achievement for students, employees and for themselves.</p>
                                                        <p> 🔹 Administrators should be role models for teachers and students.</p>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item green">
                                                <h2 className="accordion-header" id="headingThree1">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                                                    Governance
                                                    </button>
                                                </h2>
                                                <div id="collapseThree1" className="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>🔹 The governance of the Academy is a collaborative responsibility of Board members, school management, parents, staff and community.</p>
                                                        <p>🔹 Governance decisions are based upon a balance between the academic and human needs, wants and expectations of students.</p>
                                                        <p>🔹 Governance decisions are made on the basis of the strategic plan of the Academy to achieve the vision and the mission of the school.</p>
                                                        <p>🔹 Little Horizons Academy is affiliated with Brighter Horizons Academy and is a project of the Islamic Services Foundation.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeIslam