import React from 'react'

const Assessment = () => {
    return (
        <>
            <section className="tf__event_page xs_mt_95" style={{ marginTop: "22px", overflow:"hidden" }}>
                <div className="container">
                    <div className="row wow fadeInUp" data-wow-duration="1.5s">
                        <div className="col-xl-6 col-md-8 col-lg-6 m-auto">
                            <div className="tf__heading_area mb_15">
                                <h2>Assessment and Progress Tracking</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_event">
                                <div className="tf__single_event_img">
                                    <img src="./assets/images/aqta/2.jpeg" alt="event" className="img-fluid w-100" />
                                    <a className="event_category blue" style={{ textTransform: "capitalize" }} to="#">Al-Qalam Academy</a>
                                </div>
                                <div className="tf__single_event_text">

                                    <a className="title" to="event_details.html" style={{ fontSize: "20px" }}>Observation</a>
                                    <p style={{ fontSize: "13px" }}>Teachers observe students during activities to assess their progress.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_event">
                                <div className="tf__single_event_img">
                                    <img src="./assets/images/aqta/11.jpeg" alt="event" className="img-fluid w-100" />
                                    <a className="event_category orange" style={{ textTransform: "capitalize" }} to="#">Al-Qalam Academy</a>
                                </div>
                                <div className="tf__single_event_text">

                                    <a className="title" to="event_details.html" style={{ fontSize: "20px" }}>Portfolios</a>
                                    <p style={{ fontSize: "13px" }}>Maintain portfolios of students’ work (e.g., writing samples, art projects).</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_event">
                                <div className="tf__single_event_img">
                                    <img src="./assets/images/aqta/17.jpeg" alt="event" className="img-fluid w-100" />
                                    <a className="event_category green" style={{ textTransform: "capitalize" }} to="#">Al-Qalam Academy</a>
                                </div>
                                <div className="tf__single_event_text">

                                    <a className="title" to="event_details.html" style={{ fontSize: "20px" }}>Parent-Teacher Conferences</a>
                                    <p style={{ fontSize: "13px" }}> Regularly update parents on their child’s progress and
                                        provide suggestions for support at home.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <p style={{ marginTop:"20px" }}> By integrating Montessori principles into the Arabic curriculum, Al-Qalam Academy can create a
                        dynamic and engaging learning experience that fosters a deep connection to the Arabic language and Islamic culture. This approach not only enhances language skills but also
                        nurtures a love for learning and a sense of pride in the students’ Islamic identity.</p>
                </div>
            </section>

        </>
    )
}

export default Assessment