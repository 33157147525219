import React from 'react'
import SliderHomeArabicCurriculum from '../../component/slidercomponent/SliderHomeArabicCurriculum'
import { Link } from "react-router-dom";


const HomeArabicCurriculum = () => {
    return (
        <>
            {/* <section className="tf__categories mt_95" style={{overflow:"hidden"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-8 col-lg-6 m-auto wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__heading_area mb_15">
                                <h5> Al-Qalam Triangle Academy</h5>
                                <h2>Arabic Curriculum</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category light_blue">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Conversation</h3>
                                    <p>Students are given ample class time to acquire oral expression skills through dialogue and conversation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category blue">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Literary</h3>
                                    <p>Teachers explore figurative expressions to enhance students’ literary appreciation of texts.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category gray">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Writing</h3>
                                    <p>Students learn the Arabic alphabet and progress to writing words with correct spelling.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category orange">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Listening Comprehension</h3>
                                    <p>Students develop the ability to understand what they hear through audio and video materials.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category red">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Reading</h3>
                                    <p>Various methodological approaches, such as repetition, mock reading, and practicing aloud, help students reach a satisfying level of reading.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category blue">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Reading Comprehension</h3>
                                    <p>Students start with reading letters, progress to forming words, and are taught word analysis processes, and scaling to understand what they read.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className="tf__blog mt_95">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 m-auto wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__heading_area mb_15">
                                {/* <h5>Arabic Curriculum for Al-Qalam Academy</h5> */}
                                <h2 style={{ fontSize: "29px" }}>Arabic Curriculum for Al-Qalam Academy</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row blog_slider">
                        <div className="col-xl-4 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_blog">
                                <Link className="tf__single_blog_img" to="/arabic-curriculum">
                                    <img src="./assets/images/aqta/Arabic Curriculum_1.png" alt="blog" className="img-fluid w-100" />
                                </Link>
                                <div className="tf__single_blog_text">
                                    <Link className="category light_blue" to="/arabic-curriculum">Al-Qalam Academy</Link>
                                    <Link className="title" to="/arabic-curriculum">Conversation (Oral Expression Skills)</Link>
                                    <p> <b>Objective:</b> Develop fluency and confidence in spoken Arabic through interactive and practical activities.</p>
                                    <Link className="read_btn" to="/arabic-curriculum">Read More <i className="fas fa-chevron-circle-right" /></Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_blog">
                                <Link className="tf__single_blog_img" to="/writing">
                                    <img src="./assets/images/aqta/5.jpeg" alt="blog" className="img-fluid w-100" />
                                </Link>
                                <div className="tf__single_blog_text">
                                    <Link className="category green" to="/writing">Al-Qalam Academy</Link>
                                    <Link className="title" to="/writing">Writing (Alphabet and Spelling)</Link>
                                    <p><b>Objective: </b> Master the Arabic alphabet and progress to writing words and sentences.</p>
                                    <Link className="read_btn" to="/writing">Read More <i className="fas fa-chevron-circle-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_blog">
                                <Link className="tf__single_blog_img" to="/listening-comprehension">
                                    <img src="./assets/images/aqta/10.jpeg" alt="blog" className="img-fluid w-100" />
                                </Link>
                                <div className="tf__single_blog_text">
                                    <Link className="category red" to="/listening-comprehension">Al-Qalam Academy</Link>
                                    <Link className="title" to="/listening-comprehension">Listening Comprehension</Link>
                                    <p> <b> Objective: </b> Develop the ability to understand spoken Arabic through audio and video materials.</p>
                                    <Link className="read_btn" to="/listening-comprehension">Read More <i className="fas fa-chevron-circle-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_blog">
                                <Link className="tf__single_blog_img" to="/Reading">
                                    <img src="./assets/images/aqta/3.jpeg" alt="blog" className="img-fluid w-100" />
                                </Link>
                                <div className="tf__single_blog_text">
                                    <Link className="category red" to="/Reading">Al-Qalam Academy</Link>
                                    <Link className="title" to="/Reading">Reading (Methodological Approaches)</Link>
                                    <p> <b> Objective: </b> Achieve fluency in reading Arabic through repetition, mock reading, and practice.</p>
                                    <Link className="read_btn" to="/Reading">Read More <i className="fas fa-chevron-circle-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_blog">
                                <Link className="tf__single_blog_img" to="/literary">
                                    <img src="./assets/images/aqta/Arabic Curriculum_2.png" alt="blog" className="img-fluid w-100" />
                                </Link>
                                <div className="tf__single_blog_text">
                                    <Link className="category orange" to="/literary">Al-Qalam Academy</Link>
                                    <Link className="title" to="/literary">Literary (Figurative Expressions and Appreciation)</Link>
                                    <p><b>Objective: </b> Enhance appreciation for Arabic literature and figurative language.</p>
                                    <Link className="read_btn" to="/literary">Read More <i className="fas fa-chevron-circle-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_blog">
                                <Link className="tf__single_blog_img" to="/reading-comprehension">
                                    <img src="./assets/images/aqta/10.jpeg" alt="blog" className="img-fluid w-100" />
                                </Link>
                                <div className="tf__single_blog_text">
                                    <Link className="category red" to="/reading-comprehension">Al-Qalam Academy</Link>
                                    <Link className="title" to="/reading-comprehension">Reading Comprehension (Word Analysis and Understanding)</Link>
                                    <p> <b> Objective: </b>  Develop the ability to analyze and understand Arabic texts.</p>
                                    <Link className="read_btn" to="/reading-comprehension">Read More <i className="fas fa-chevron-circle-right" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </>
    )
}

export default HomeArabicCurriculum