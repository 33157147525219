import React from 'react'
import HomeBanner from './home/HomeBanner'
import HomeAboutUs from './home/HomeAboutUs'
import HomeFaq from './home/HomeFaq'
import HomePrograms from './home/HomePrograms'
import HomeArabicCurriculum from './home/HomeArabicCurriculum'
import HomeResources from './home/HomeResources'
import HomeFamilyPortal from './home/HomeFamilyPortal'
import HomeIslam from './home/HomeIslam'
import NowEnrolling from './nowenrolling/NowEnrolling'
import WhyChooseUs from './home/WhyChooseUs'
import OurCorePrinciples from './home/OurCorePrinciples'
import SampleWeekly from './home/SampleWeekly'
import MontessoriInspired from './home/MontessoriInspired'
import Assessment from './home/Assessment'


const Home = () => {
  return (
    <>
      <HomeBanner />
      <HomeAboutUs />
      <HomeIslam />
      <HomePrograms />
      <WhyChooseUs />
      <HomeArabicCurriculum />
      <HomeResources />
      <HomeFamilyPortal />
      <HomeFaq />
      <OurCorePrinciples />
      <SampleWeekly />
      <MontessoriInspired />
      <Assessment />
      <NowEnrolling />





    </>
  )
}

export default Home