import React from 'react'
import Footer from '../../component/layout/Footer'

const Writing = () => {
    return (
        <>
            <section className="tf__event_details mt_195 xs_mt_100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <br />
                            <br />
                            <br />
                            <div className="tf__event_details_img wow fadeInUp" data-wow-duration="1.5s">
                                <img src="./assets/images/aqta/Copy1.jpeg" alt="event details" className="img-fluid w-100" />
                            </div>
                            <div className="tf__event_details_text mt_35 wow fadeInUp" data-wow-duration="1.5s">
                                <span className="categories" style={{ textTransform: "capitalize" }}>Al-Qalam Academy
                                </span>
                                <h2>Writing (Alphabet and Spelling)
                                </h2>
                                <p> <b> Objective: </b> Master the Arabic alphabet and progress to writing words and sentences.
                                </p>

                                <div className="row">
                                    {/* <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_img">
                                            <img src="./assets/images/event_det_center_img.jpg" alt="event" className="img-fluid w-100" />
                                        </div>
                                    </div> */}
                                    <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_text">
                                            <h3>Montessori Approach </h3>
                                            <ul>
                                                <li>Sandpaper Letters: Use Arabic sandpaper letters to help students trace and learn the
                                                    shapes and sounds of the alphabet.</li>

                                                <li>Moveable Alphabet: Introduce an Arabic moveable alphabet for students to form words and practice spelling.</li>

                                                <li>Writing Practice: Provide Arabic writing materials (e.g., chalkboards, whiteboards, or paper) for independent practice.</li>

                                            </ul>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s">
                                    <div className="tf__event_det_center_text">
                                        <h3>Materials: </h3>
                                        <ul>


                                            <li>Arabic sandpaper letters.
                                            </li>
                                            <li> Moveable Arabic alphabet</li>
                                            <li>Writing tools (e.g., chalk, markers, pencils).
                                            </li>


                                        </ul>

                                    </div>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />



        </>
    )
}

export default Writing