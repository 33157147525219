import React from 'react'
import { Link } from "react-router-dom";
import HomeIslam from '../home/HomeIslam';
import Footer from '../../component/layout/Footer';
import WhyChooseUs from '../home/WhyChooseUs';
import OurCorePrinciples from '../home/OurCorePrinciples';
import OurDirector from '../ourdirector/OurDirector';


const AboutUs = () => {
  return (
    <>
      <div>
        {/* <section className="tf__breadcrumb" style={{ background: 'url(./assets/images/breadcrumb_bg_1.jpg)', overflow:"hidden" }}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tf__breadcrumb_text">
                  <h2>About Us</h2>
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><a to="">About Us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <br />
        <br />
        <br />
        <section className="tf__courses_details mt_195 xs_mt_100 mt-lg-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-8">
                <div className="tf__courses_details_area">
                  <div className="tf__courses_details_img">
                    <img src="./assets/images/AcademyBanner_6.jpg" alt="courses" className="img-fluid w-100" />
                  </div>
                  <div className="tf__courses_det_text">
                    <h2>About Us</h2>
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                          Mission</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Vision</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Our Preschool’s Mission encompasses</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-contacts-tabs" data-bs-toggle="pill" data-bs-target="#pills-contacts" type="button" role="tab" aria-controls="pills-contacts" aria-selected="false">Our Core Principles</button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                        <div className="tf__course_overview">
                          <ul>
                            <li style={{textAlign:"justify"}}>We aspire to nurture rising leaders by providing an inclusive, exceptional, and Islamic environment that empowers children to reach their fullest potential. Grounded in the teachings of Islam and inspired by Montessori principles, we strive to create a nurturing
                              space where children develop a profound love for Allah, embody Islamic values, and grow into compassionate, responsible, and globally conscious individuals.Through a child-centered approach, we foster the spiritual, academic, and social growth of our young learners. By respecting each child’s unique abilities and nurturing their natural curiosity, we aim to cultivate confident, independent, and morally grounded individuals who are inspired to aim for the stars and contribute positively to their communities and the world.</li>

                          </ul>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                        <div className="tf__course_overview">
                          <ul>
                            <li style={{ textAlign: "justify" }}>At Al-Qalam Triangle Academy, we envision confident and inspired young Muslims who embody a lifelong passion for learning, rooted in Islamic values, knowledge, and character. As a leading Islamic Montessori school, we strive to create a vibrant, nurturing community where children develop a deep connection to Allah, a love for exploration, and a commitment to serving humanity. Guided by the teachings of Islam and the principles of Montessori education, we aim to nurture young hearts and minds to become exemplary individuals—spiritually grounded, intellectually curious, and socially responsible. Our vision is to empower children to achieve success in this life and the hereafter by fostering their innate potential, cultivating their moral character, and inspiring them to be compassionate leaders and global citizens.</li>


                          </ul>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex={0}>
                        <div className="tf__course_overview">

                          <ul>
                            <li style={{textAlign:"justify"}}>Confident and inspired young Muslims with a lifelong passion for learning. At Al-Qalam Triangle Academy, our vision expands by becoming a leading Islamic School that nurtures young hearts and minds, preparing them to become exemplary individuals grounded in Islamic values, knowledge and character. We strive to create a vibrant learning and exploratory community where children embark on a lifelong journey of faith, education, and service to humanity so they may achieve success in this life and the hereafter.</li>
                          </ul>


                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-contacts" role="tabpanel" aria-labelledby="pills-contacts-tabs" tabIndex={0}>
                        <div className="tf__course_overview">

                          <ul>
                            <li style={{textAlign:"justify"}}>At our Al-Qalam Triangle Montessori Islamic school, we are dedicated to nurturing confident, inspired, and morally grounded young Muslims through a holistic Montessori Islamic education. Our mission is to cultivate a deep love for Allah, a passion for lifelong learning, and a commitment to serving humanity. By integrating Islamic teachings with Montessori principles, we create a vibrant, inclusive, and nurturing environment where children thrive spiritually, academically, and socially.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>

      <OurDirector />


      <HomeIslam />
      <WhyChooseUs />
      <OurCorePrinciples />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />

    </>
  )
}

export default AboutUs