import React from 'react'
import Footer from '../../component/layout/Footer'

const ListeningComprehension = () => {
    return (
        <>
            <section className="tf__event_details mt_195 xs_mt_100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <br />
                            <br />
                            <br />
                            <div className="tf__event_details_img wow fadeInUp" data-wow-duration="1.5s">
                                <img src="./assets/images/aqta/Copy1.jpeg" alt="event details" className="img-fluid w-100" />
                            </div>
                            <div className="tf__event_details_text mt_35 wow fadeInUp" data-wow-duration="1.5s">
                                <span className="categories" style={{ textTransform: "capitalize" }}>Al-Qalam Academy
                                </span>
                                <h2>Listening Comprehension

                                </h2>
                                <p> <b> Objective: </b> Develop the ability to understand spoken Arabic through audio and video materials.

                                </p>

                                <div className="row">
                                    {/* <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_img">
                                            <img src="./assets/images/event_det_center_img.jpg" alt="event" className="img-fluid w-100" />
                                        </div>
                                    </div> */}
                                    <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_text">
                                            <h3>Montessori Approach </h3>
                                            <ul>
                                                <li>Audio Materials: Use Quranic recitations, Arabic songs, and stories in audio format.</li>

                                                <li>Listening Stations: Set up listening stations with headphones where students can listen
                                                to Arabic audio materials and answer comprehension questions.</li>

                                                <li> Interactive Activities: Play games like “Simon Says” in Arabic or give verbal
                                                instructions for students to follow.</li>

                                            </ul>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s">
                                    <div className="tf__event_det_center_text">
                                        <h3>Materials: </h3>
                                        <ul>


                                            <li>Audio recordings of Quranic recitations, stories, and songs.
                                            </li>
                                            <li> Headphones and listening stations</li>
                                        </ul>

                                    </div>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />



        </>
    )
}

export default ListeningComprehension