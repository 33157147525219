import React from 'react'
import Footer from '../../component/layout/Footer'

const Reading = () => {
    return (
        <>
            <section className="tf__event_details mt_195 xs_mt_100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <br />
                            <br />
                            <br />
                            <div className="tf__event_details_img wow fadeInUp" data-wow-duration="1.5s">
                                <img src="./assets/images/aqta/Copy1.jpeg" alt="event details" className="img-fluid w-100" />
                            </div>
                            <div className="tf__event_details_text mt_35 wow fadeInUp" data-wow-duration="1.5s">
                                <span className="categories" style={{ textTransform: "capitalize" }}>Al-Qalam Academy
                                </span>
                                <h2>Reading (Methodological Approaches)
                                </h2>
                                <p> <b> Objective: </b> : Achieve fluency in reading Arabic through repetition, mock reading, and practice.
                                </p>

                                <div className="row">
                                    {/* <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_img">
                                            <img src="./assets/images/event_det_center_img.jpg" alt="event" className="img-fluid w-100" />
                                        </div>
                                    </div> */}
                                    <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_text">
                                            <h3>Montessori Approach </h3>
                                            <ul>
                                                <li>Phonetic Reading: Start with phonetic Arabic words and gradually introduce more
                                                    complex vocabulary.</li>

                                                <li>Repetition and Practice: Use Montessori three-period lessons to teach reading: (1)
                                                    Introduce the word, (2) Practice identifying the word, and (3) Confirm mastery.
                                                </li>

                                                <li>Reading Aloud: Encourage students to read aloud from simple Arabic texts,
                                                    progressing to more advanced materials.</li>

                                            </ul>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s">
                                    <div className="tf__event_det_center_text">
                                        <h3>Materials: </h3>
                                        <ul>


                                            <li>Phonetic Arabic word cards.
                                            </li>
                                            <li> Simple Arabic storybooks.
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />



        </>
    )
}

export default Reading