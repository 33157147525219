import React from 'react'

const HomePrograms = () => {
    return (
        <>
            <section className="tf__blog_2 mt_95"  style={{overflow:"hidden"}}>
                <div className="container">
                    <div className="row wow fadeInUp" data-wow-duration="1.5s">
                        <div className="col-xl-6 col-xxl-5 col-md-8 col-lg-6 m-auto">
                            <div className="tf__heading_area mb_15">
                                {/* <h5>Programs</h5> */}
                                <h5>Prekindergarten</h5>
                                <h2>Age Groups Curriculum</h2>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 wow fadeInLeft" data-wow-duration="1.5s">
                            <div className="tf__single_blog">
                                <a className="tf__single_blog_img" to="blog_details.html">
                                    <img src="./assets/images/Pupils gain proficiency.png" alt="blog" className="img-fluid w-100" />
                                    {/* <img src="./assets/images/aqta/16.JPEG" alt="blog" className="img-fluid w-100" /> */}
                                </a>
                                <div className="tf__single_blog_text">
                                    <a className="category light_blue" to="#">Math</a>
                                  
                                    <p className='mt-lg-4'>Pupils gain proficiency in counting and learn how to manipulate collections using methods. They get up the ability to describe object properties.</p>
                                    <a className="category" to="#">Science</a>

                                  
                                    <p className='mt-lg-4'>Through investigations, students learn about materials, motion, and energy. They use their senses to make observations, forecasts, and classifications.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7  wow fadeInRight" data-wow-duration="1.5s">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="tf__single_blog_2">
                                        <a className="tf__single_blog_2_img" to="blog_details.html">
                                            <img src="./assets/images/Through discussions.png" alt="blog" className="img-fluid w-100" />
                                            {/* <img src="./assets/images/aqta/18.JPEG" alt="blog" className="img-fluid w-100" /> */}
                                        </a>
                                        <div className="tf__single_blog_text_2">
                                            <a className="category" to="#">English</a>
                                            <p className='mt-lg-4'>Through discussions and stories, students develop their listening and comprehension abilities. They pick up conversational skills, efficient language use, and verbal and nonverbal self-expression.</p>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="tf__single_blog_2">
                                        <a className="tf__single_blog_2_img" to="blog_details.html">
                                            <img src="./assets/images/Pupils comprehend historical.png" alt="blog" className="img-fluid w-100" />
                                            {/* <img src="./assets/images/aqta/1.JPEG" alt="blog" className="img-fluid w-100" /> */}
                                        </a>
                                        <div className="tf__single_blog_text_2">
                                            <a className="category green" to="#">Social Studies</a>
                                            <p className='mt-lg-4'>Pupils comprehend historical events and how they relate to the present and future. They investigate their neighborhood, discover roles, traditions, and holidays, and get an understanding of American values and national identity.</p>
                                            <br/>
                                            <br/>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomePrograms