import React from 'react'
import Footer from '../../component/layout/Footer'

const ReadingComprehension = () => {
    return (
        <>
            <section className="tf__event_details mt_195 xs_mt_100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <br />
                            <br />
                            <br />
                            <div className="tf__event_details_img wow fadeInUp" data-wow-duration="1.5s">
                                <img src="./assets/images/aqta/Copy1.jpeg" alt="event details" className="img-fluid w-100" />
                            </div>
                            <div className="tf__event_details_text mt_35 wow fadeInUp" data-wow-duration="1.5s">
                                <span className="categories" style={{ textTransform: "capitalize" }}>Al-Qalam Academy
                                </span>
                                <h2>Reading Comprehension (Word Analysis and Understanding)

                                </h2>
                                <p> <b> Objective: </b> : Develop the ability to analyze and understand Arabic texts
                                </p>

                                <div className="row">
                                    {/* <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_img">
                                            <img src="./assets/images/event_det_center_img.jpg" alt="event" className="img-fluid w-100" />
                                        </div>
                                    </div> */}
                                    <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_text">
                                            <h3>Montessori Approach </h3>
                                            <ul>
                                                <li>Word Analysis: Use Montessori word study materials (e.g., word cards, puzzles) to
                                                    teach students how to break down and analyze Arabic words.</li>

                                                <li>Comprehension Activities: Provide comprehension questions and activities that
                                                    encourage critical thinking (e.g., “What is the main idea?” or “What did you learn from
                                                    this story?”).

                                                </li>

                                                <li>Sequencing and Summarizing: Use sequencing cards or story maps to help students
                                                    retell stories in their own words.</li>

                                            </ul>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s">
                                    <div className="tf__event_det_center_text">
                                        <h3>Materials: </h3>
                                        <ul>


                                            <li>Arabic word cards and puzzles.
                                            </li>
                                            <li> Sequencing cards and story maps.
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />



        </>
    )
}

export default ReadingComprehension