import React from 'react'

const OurDirector = () => {
    return (
        <>

            <section className="tf__home_2_about pt_100" style={{ background: 'url(./assets/images/about_2_bg.jpg)', paddingBottom: "37px", overflow:"hidden" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-duration="1.5s">
                            <div className="tf__home_2_about_img">
                                <img src="./assets/images/aqta/6.jpeg" alt="about us" className="img-fluid w-100" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 wow fadeInRight" data-wow-duration="1.5s">
                            <div className="tf__home_2_about_text">
                                <div className="tf__heading_area tf__heading_area_left mb_25">
                                    <h5>Message Director</h5>
                                    <h2>A Message from Our Director</h2>
                                </div>
                                <p style={{ textAlign: "justify" }}>I'm Nasreen Bakhsh, and I am deeply honored to lead Al Qalam Triangle Academy (Islamic
                                    Montessori School) as its Director and Lead Teacher. This is not my first venture into the world
                                    of Islamic education; I have previously established and led another successful Muslim school,
                                    and I am excited to bring my passion and experience to our academy. With 36 years of
                                    experience in Montessori education and a background that includes a BA in Islamic Studies and
                                    an MA in Urdu Literature, I have dedicated my career to nurturing young minds and cultivating a
                                    deep love for learning</p>
                                <p style={{ textAlign: "justify" }}>Throughout my career, I have had the privilege of training teachers and guiding them in the
                                    Montessori philosophy, ensuring that they bring the best educational practices to their students.
                                    My personal journey with Montessori education extends beyond my professional life, as I have
                                    raised my own four children in Montessori settings. This experience has reinforced my belief in
                                    the transformative power of Montessori education in shaping confident, independent, and
                                    compassionate individuals.
                                </p>
                            </div>
                        </div>

                        <p style={{ textAlign: "center", color: "black" }}><b>As we embark on this new academic year, my goals are to:</b></p>
                        <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s" style={{marginLeft:"7px", color:"black"}}>
                            <div className="tf__event_det_center_text">
                                <ul >
                                    <li>Build a robust academic foundation for each child through authentic Montessori
                                    methods.
                                    </li>
                                    <li>  Foster personal growth, confidence, and effective communication skills in a nurturing
                                    environment.
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default OurDirector