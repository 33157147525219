import React from 'react'

const OurCorePrinciples = () => {
    return (
        <>
            <section className="tf__faq tf__faq_page pt_190 xs_pt_95" style={{ marginBottom: "-169px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-8 col-lg-6 m-auto wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__heading_area mb_10">
                                <h2>Our Core Principles</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-10 col-lg-9 m-auto wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__faq_text">
                                <div className="tf__faq_accordion">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item orange">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Spiritual Nourishment
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>We strive to cultivate a profound love and connection with Allah through
                                                        age-appropriate Islamic teachings, stories of the Prophets (peace be upon them), and
                                                        the recitation of the Quran. Our children learn to develop gratitude, empathy, and respect
                                                        for all of Allah’s creation, fostering a strong spiritual foundation that guides their actions
                                                        and choices.
                                                        .</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item green">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Excellence in Education
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Our educational approach combines Islamic principles with Montessori methodologies
                                                        to provide a high-quality early childhood education. We foster cognitive, emotional,
                                                        physical, and social growth through hands-on, experiential learning. By nurturing curious
                                                        minds and encouraging exploration, we lay the foundation for a lifelong love of learning.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item red">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Moral Values and Character Development
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Through Islamic ethics and moral teachings, we aim to develop strong character traits
                                                        in our students. Honesty, kindness, compassion, and respect are core values that we
                                                        instill in all aspects of their lives. Our Montessori-inspired “Grace and Courtesy” lessons
                                                        reinforce these values, helping children grow into exemplary individuals.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item blue">
                                            <h2 className="accordion-header" id="headingThree1">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                                                    Inclusivity and Diversity
                                                </button>
                                            </h2>
                                            <div id="collapseThree1" className="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>We celebrate the diversity of our community and promote an inclusive atmosphere
                                                        where children from all backgrounds feel welcomed and cherished. By teaching our
                                                        students to appreciate and respect different cultures and beliefs, we foster a spirit of
                                                        unity and understanding that reflects the beauty of the Ummah.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item orange">
                                            <h2 className="accordion-header" id="headingThree11">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree11" aria-expanded="false" aria-controls="collapseThree11">
                                                    Parent and Community Engagement
                                                </button>
                                            </h2>
                                            <div id="collapseThree11" className="accordion-collapse collapse" aria-labelledby="headingThree11" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>We recognize the vital role of parents in their child’s development and education.
                                                        Through strong partnerships, we actively involve parents in their child’s learning journey,
                                                        ensuring a seamless connection between school and home. We also engage with the
                                                        local Islamic community, reinforcing the values of collaboration and shared
                                                        responsibility.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item green">
                                            <h2 className="accordion-header" id="headingThree12">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree12" aria-expanded="false" aria-controls="collapseThree12">
                                                    Safe and Nurturing Environment
                                                </button>
                                            </h2>
                                            <div id="collapseThree12" className="accordion-collapse collapse" aria-labelledby="headingThree12" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>The emotional and physical well-being of our students is our top priority. We maintain a
                                                        safe, secure, and caring environment where children feel confident to explore, create, and express themselves freely. Our classrooms are designed to be peaceful, inviting
                                                        spaces that inspire learning and growth.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item blue">
                                            <h2 className="accordion-header" id="headingThree13">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree13" aria-expanded="false" aria-controls="collapseThree13">
                                                    Play-Based Learning
                                                </button>
                                            </h2>
                                            <div id="collapseThree13" className="accordion-collapse collapse" aria-labelledby="headingThree13" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>We believe that play is an essential part of early childhood education. Through
                                                        play-based learning, we encourage imagination, creativity, and problem-solving skills,
                                                        allowing children to discover and learn at their own pace. Our Montessori materials and
                                                        activities are carefully chosen to support this approach, ensuring that learning is both
                                                        meaningful and enjoyable.</p>
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            

        </>
    )
}

export default OurCorePrinciples