import React from 'react'

const MontessoriInspired = () => {
    return (
        <>

            <section className="tf__popular_services_2 mt_95">
                <div className="container">
                    <div className="row wow fadeInUp" data-wow-duration="1.5s">
                        <div className="col-xl-7 col-xxl-6 col-md-8 col-lg-6 m-auto">
                            <div className="tf__heading_area mb_15">
                                <h2>Montessori-Inspired Arabic Classroom Environment.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-md-6 col-lg-4 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_services">
                                <span><i className="fas fa-books-medical" /></span>
                                <h3>Prepared Environment</h3>
                                <p> Create an Arabic learning area with Montessori materials, such
                                    as sandpaper letters, moveable alphabets, and reading corners with Arabic books.</p>
                                <a to="#"><i className="fas fa-long-arrow-right" /></a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 col-lg-4 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_services">
                                <span><i className="fal fa-book" /></span>
                                <h3>Cultural Integration</h3>
                                <p>Decorate the classroom with Arabic calligraphy, Islamic art, and
                                    maps of Arabic-speaking countries.</p>
                                <a to="#"><i className="fas fa-long-arrow-right" /></a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 col-lg-4 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_services">
                                <span><i className="fal fa-car-bus" /></span>
                                <h3>Practical Life Connection</h3>
                                <p style={{textAlign:""}}>Integrate Arabic into practical life activities, such as cooking
                                    traditional Arabic dishes or learning Arabic names for everyday objects.
                                </p>
                                <a to="#"><i className="fas fa-long-arrow-right" /></a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default MontessoriInspired