import React from 'react'

const HomeFaq = () => {
    return (
        <>
            <section className="tf__faq mt_100 pt_95 xs_pt_100 pb_100" style={{ background: 'url(./assets/images/faq_bg.png)', overflow:"hidden" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-duration="1.5s">
                            <div className="tf__faq_img">
                                <img src="./assets//images/OUR EDUCATION FAQ.png" alt="faqs" className="img-fluid w-100" />
                                {/* <img src="./assets/images/aqta/6.JPEG" alt="faqs" className="img-fluid w-100" /> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 wow fadeInRight" data-wow-duration="1.5s">
                            <div className="tf__faq_text">
                                <div className="tf__heading_area tf__heading_area_left mb_25">
                                    <h5>OUR EDUCATION FAQ</h5>
                                    {/* <h2>District is Made of about Students Childhood.</h2> */}
                                </div>
                                    <div className="tf__faq_accordion">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item orange">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Where is  Al-Qalam Triangle Academy located?
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p> 5122 Revere Rd, Durham, NC 27713.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item green">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Is this a new program?
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Al-Qalam Triangle Academy(AQTA) was established by Jamaat Ibad Ar-Rahman(JIAR) in 2024. We are starting with the Pre-K program in 2024 and intend to start the elementary school for 2025-26 school year.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item red">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Is  Al-Qalam Triangle Academy an accredited preschool?
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p> Al-Qalam Triangle Academy is seeking accreditation and has applied for Early Learning Candidacy through Cognia.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item blue">
                                            <h2 className="accordion-header" id="headingThree1">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                                                How will the teacher-student ratios be implemented in the center?
                                                </button>
                                            </h2>
                                            <div id="collapseThree1" className="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Age 3 = 1:15</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeFaq