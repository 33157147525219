import React from 'react'
import Footer from '../../component/layout/Footer'

const Literary = () => {
    return (
        <>
            <section className="tf__event_details mt_195 xs_mt_100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <br />
                            <br />
                            <br />
                            <div className="tf__event_details_img wow fadeInUp" data-wow-duration="1.5s">
                                <img src="./assets/images/aqta/Copy1.jpeg" alt="event details" className="img-fluid w-100" />
                            </div>
                            <div className="tf__event_details_text mt_35 wow fadeInUp" data-wow-duration="1.5s">
                                <span className="categories" style={{ textTransform: "capitalize" }}>Al-Qalam Academy
                                </span>
                                <h2>Literary (Figurative Expressions and Appreciation)
                                </h2>
                                <p> <b> Objective: </b>  Enhance appreciation for Arabic literature and figurative language.
                                </p>

                                <div className="row">
                                    {/* <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_img">
                                            <img src="./assets/images/event_det_center_img.jpg" alt="event" className="img-fluid w-100" />
                                        </div>
                                    </div> */}
                                    <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-duration="1.5s">
                                        <div className="tf__event_det_center_text">
                                            <h3>Montessori Approach</h3>
                                            <ul>
                                                <li>Storytelling: Use Montessori storytelling techniques to share Arabic folktales, Quranic
stories, and poetry.
</li>
                                                <li>● Literature Circles: Introduce age-appropriate Arabic texts and encourage students to
                                                discuss themes, characters, and figurative language.</li>
                                                <li>Art Integration: Combine literature with art by having students illustrate stories or create
                                                their own figurative expressions.</li>
                                               
                                            </ul>

                                        </div>
                                    </div>
                                </div>

                                <h3>Materials:</h3>
                                <li> Arabic storybooks, poetry collections, and Quranic stories </li>
                                <li>Art supplies for illustrating stories.</li>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />



        </>
    )
}

export default Literary