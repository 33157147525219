import React from 'react'

const SampleWeekly = () => {
    return (
        <>
            <section className="tf__blog_2 mt_95" style={{ overflow: "hidden" }}>
                <div className="container">
                    <div className="row wow fadeInUp" data-wow-duration="1.5s">
                        <div className="col-xl-6 col-xxl-5 col-md-8 col-lg-6 m-auto">
                            <div className="tf__heading_area mb_15">
                                {/* <h5>Programs</h5> */}
                                {/* <h5>Prekindergarten</h5> */}
                                <h2 style={{ fontSize: "" }}>Sample Weekly Arabic Lesson Plan</h2>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 wow fadeInLeft" data-wow-duration="1.5s">
                            <div className="tf__single_blog">
                                <a className="tf__single_blog_img" to="blog_details.html">
                                    <img src="./assets/images/Pupils gain proficiency.png" alt="blog" className="img-fluid w-100" />
                                    {/* <img src="./assets/images/aqta/16.JPEG" alt="blog" className="img-fluid w-100" /> */}
                                </a>
                                <div className="tf__single_blog_text">
                                    <a className="category light_blue" to="#">Monday</a>

                                    <p className='mt-lg-4'>  <b>Conversation:</b> Role-playing in the Arabic conversation corner</p>
                                    <p className='mt-lg-4'>  <b> Writing:</b> Practicing Arabic letters using sandpaper letters.</p>
                                    <a className="category" to="#">Tuesday</a>
                                    <p className='mt-lg-4'>  <b>Literary:</b> Storytelling with an Arabic folktale.</p>
                                    <p className='mt-lg-4'>  <b> Listening Comprehension:</b>  Listening to an Arabic song and discussing its meaning.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7  wow fadeInRight" data-wow-duration="1.5s">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="tf__single_blog_2">
                                        <a className="tf__single_blog_2_img" to="blog_details.html">
                                            <img src="./assets/images/Through discussions.png" alt="blog" className="img-fluid w-100" />
                                            {/* <img src="./assets/images/aqta/18.JPEG" alt="blog" className="img-fluid w-100" /> */}
                                        </a>
                                        <div className="tf__single_blog_text_2">
                                            <a className="category" to="#">Wednesday</a>
                                            <p className='mt-lg-4'>  <b>Reading:</b> Reading aloud from a simple Arabic text.</p>
                                            <p className='mt-lg-4'>  <b> Writing:</b>  Forming words with the moveable alphabet.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="tf__single_blog_2">
                                        <a className="tf__single_blog_2_img" to="blog_details.html">
                                            <img src="./assets/images/Pupils comprehend historical.png" alt="blog" className="img-fluid w-100" />
                                            {/* <img src="./assets/images/aqta/1.JPEG" alt="blog" className="img-fluid w-100" /> */}
                                        </a>
                                        <div className="tf__single_blog_text_2">
                                            <a className="category green" to="#">Thursday</a>
                                            <p className='mt-lg-4'><b>Reading Comprehension: </b> Analyzing a short Arabic story using word cards. </p>
                                            <p className='mt-lg-4'><b>Art Integration: </b>  Illustrating the story. </p>
                                            <a className="category green" to="#">Friday</a>
                                            <p className='mt-lg-4'><b>Conversation: </b> Group discussion on a topic of interest. </p>
                                            <p className='mt-lg-4'><b>Listening Comprehension: </b> Following verbal instructions in Arabic. </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SampleWeekly